/*---- Start Common Css ----*/
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
html {
    scroll-behavior: smooth;
}
body {
    background : #F2F2F2;
    font-family: 'Montserrat', sans-serif;
}
#root {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    /* overflow-x: hidden; */
}
.authNavBar {
    position: sticky !important;
    /* top: 68px; */
    top: -1px;

    /* overflow: hidden; */
    z-index: 9;
    background-image: linear-gradient(to right, #1C3276 ,#4C69C3, #1C3276) !important;

}
.navbarContain {
    background-image: linear-gradient(to right, #1C3276 ,#4C69C3, #1C3276);
    padding: 10px 0;
}
#main {
    margin: 40px 0;
    min-height: calc(100vh - 66px - 311px);
    display: flex;
    flex-direction: column;
    /* place-content: center; */
}
ul
{
    padding: 0;
    margin: 0;
}
ul li
{
    list-style: none;
}
a
{
    text-decoration: none;
}
a:-webkit-any-link:focus-visible
{
    outline: 0;
}
textarea
{
    resize: none;
}
textarea:focus-visible {
    outline: 0;
}
b{
    font-weight: 700;
}
.barIcon {
    font-size: 32px;
    color: #fff;
}
.containerBox
{
    background-color: #fff;
    border-radius: 8px;
    padding: 20px 24px;
    position: relative;
    height: 100%;
}
.headTxt
{
    font-size: 30px;
    color: #000000;
    margin: 0;
}
.headTxtBlue {
    font-size: 27px;
    color: #435FB5;
    margin: 0;
    font-weight: 500;
}
.headTxtBlueSmall {
    font-size: 22px;
    color: #435FB5;
    margin: 0;
    font-weight: 600;
    letter-spacing: 1px;
}
.heading_
{
    font-size: 20px;
    color: #262626;
    margin: 0;
    font-weight: 700;
}
.headingSmall_
{
    font-size: 16px;
    color: #343434;
    margin: 0;
    font-weight: 500;

}
.headingTitle_
{
    font-size: 22px;
    color: #343434;
    margin: 0;
    font-weight: 600;
}
.headingTitleSmall_ {
    font-size: 12px;
    color: #343434;
    margin: 0;
    font-weight: bold;
    cursor: pointer;
}
.headingTitleLight_
{
    font-size: 12px;
    color: #343434;
    margin: 0;
    font-weight: 500;
}
.heading_Blue
{
    font-size: 30px;
    /* color: #1C3276; */
    color: #405cb1;
    margin: 0;
    font-weight: 400;
    letter-spacing: 2px;
    /* line-height: 57px; */
}
.homePage .heading_Blue {
    color: #405cb1;
 
}
.textInner
{
    font-size: 12px;
    color: #575757;
    margin: 0;
}
.textInnerOuter
{
    font-size: 12px;
    color: #7A7A7A;
    margin: 0;
}
.subtextInner
{
    font-size: 15px;
    color: #8C8C8C;
    margin: 0;
}
.innerTxt
{
    font-size: 16px;
    color: #262626;
    margin: 0;
    font-weight: 500;
}
.innerSubtext {
    color: #343434;
    font-size: 16px;
    margin: 0;
    font-weight: 500;
    /* padding-left: 15px; */
}
.innerSubtextSmall {
    font-size: 12px;
    color: #4C4C4C;
    margin: 0;
    font-weight: 500;
    text-align: justify;
}
.innerSubtextBlue
{
    color: #1C3276;
    font-size: 18px;
    font-weight: 500;
}
.highLightTxt {
    color: #3B57A9;
    font-weight: bold;
}
.primaryBtn {
    background: transparent linear-gradient(0deg, #388E1E 0%, #4EBC2D 100%);
    border-radius: 8px;
    color: #fff !important;
    font-size: 22px;
    border: none;
    padding: 12px 14px !important;
    /* display: block; */
    /* width: 100%; */
    font-weight: 500 !important;
}
.primaryBtnOther
{
    background: transparent linear-gradient(0deg, #388E1E 0%, #4EBC2D 100%);
    border-radius: 5px;
    color: #fff !important;
    font-size: 15px;
    border: none;
    padding: 12px 20px !important;
    /* width: 223px; */
    font-weight: 500 !important;
}
.primaryBtnSmall
{
    background: transparent linear-gradient(0deg, #388E1E 0%, #4EBC2D 100%);
    border-radius: 5px;
    color: #fff !important;
    font-size: 13px;
    border: none;
    padding: 10px 20px !important;
    font-weight: 600;
}
.primaryBtnSmall.bgBlue{
    background: #3b56a7 !important;
}
.secondaryBtn {
    background: #1C3276;
    color: #Fff;
    border-radius: 5px;
    padding: 12px 20px !important;
    font-size: 15px;
    font-weight: 500;
    border: none;
    /* width: 223px; */
}
.secondaryBtnOuter {
    background: #3B56A7;
    color: #Fff;
    border-radius: 5px;
    padding: 10px 20px !important;
    font-size: 13px;
    font-weight: 600;
    border: none;
}
.secondaryBtnSmall {
    background: #3B56A7;
    color: #Fff;
    border-radius: 5px;
    padding: 6px 20px !important;
    font-size: 12px;
    font-weight: 500;
    border: none;
}
.outerBtn {
    display: inline-block;
    background: #405cb1;
    color: #fff;
    border-radius: 5px;
    padding: 12px 34px;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
}
.linkTxt {
    font-size: 16px;
    color: #364F9F;
    display: inline-block;
    line-height: normal;
    font-weight: 500;
    cursor: pointer;
}
.otherLink
{
    /* font-size: 16px; */
    color: #388E1E;
    display: inline-block;
    line-height: normal;
}
.img-container
{
    position: relative;
}
/* Start Custom Checkbox */
.checkBoxTxt {
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: #575757;
    font-size: 13px;
    line-height: 25px;
    letter-spacing: 1px;
} 
.checkBox {
    appearance: none;
    display: none;
}
.checkBoxTxt span
{
    display: inline-block;
    text-align: start;
}
.checkBoxTxt:before {
    content:'';
    -webkit-appearance: none;
    /* background-color: transparent; */
    border: 2px solid #D3D3D3;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 10px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 12px;
    border-radius: 7px;
}
.checkBox:checked + .checkBoxTxt:before
{
    background: #388E1E;
    border: 2px solid #388E1E;
}
.checkBox:checked + .checkBoxTxt:after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    transform: translate(0%, -60%) rotate(45deg);
    left: 9px;
    width: 6px;
    height: 14px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    }
/* End Custom Checkbox */
/* Start CustomRadio Button */
.customRadio:checked,
.customRadio:not(:checked) {
    position: absolute;
    left: -9999px;
}
.customRadio:checked + label,
.customRadio:not(:checked) + label
{
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666;
}
.customRadio:checked + label:before,
.customRadio:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    border: 1px solid #fff;
    border-radius: 100%;
    background: transparent;
}
.customRadio:checked + label:after,
.customRadio:not(:checked) + label:after {
    content: '';
    width: 10px;
    height: 10px;
    background: #fff;
    position: absolute;
    top: 5px;
    left: 5px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
.customRadio:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
.customRadio:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}
/* End CustomRadio Button */
/* Scrollbar-Css */
.scrollbar
{
    background-image: linear-gradient(to right, #1C3276 ,#4C69C3, #1C3276);
    width: 6px;
    height: 10%;
}
.scrollbar-track
{
    background-color:#D9D9D9
}
.scrollbar-thumb {
    background-image: linear-gradient(to right, #1C3276 ,#4C69C3, #1C3276);
    border-radius:16px;
    border:0px solid #D9D9D9;
}
::-webkit-scrollbar
{
    background-image: linear-gradient(to right, #1C3276 ,#4C69C3, #1C3276);
    width: 6px;
    height: 10%;
}
::-webkit-scrollbar-track {
    background-color:#D9D9D9
}
::-webkit-scrollbar-thumb {
    background-image: linear-gradient(to right, #1C3276 ,#4C69C3, #1C3276);
    border-radius:16px;
    border: 0px solid #D9D9D9;
}
::-moz-scrollbar
{
    background-image: linear-gradient(to right, #1C3276 ,#4C69C3, #1C3276);
    width: 6px;
    height: 10%;
}
::-moz-scrollbar-track {
    background-image: linear-gradient(to right, #1C3276 ,#4C69C3, #1C3276);
}
::-moz-scrollbar-thumb {
    background-color:#babac0;
    border-radius:16px;
    border: 0px solid #fff;
}
/* End Scrollbar Css */
button:focus, button:focus-visible, .form-select:focus, .form-select:focus-visible, .form-control:focus, .form-control:focus-visible
{
    box-shadow: none;
    outline: none;
}
button:focus:not(:focus-visible) {
    outline: 0;
    box-shadow: none;
}
input:focus-visible {
    outline: 0px;
}
button {
    background: transparent;
    outline: 0;
    border: 0;
}
.customFormControl {
    width: 100%;
    border: 1px solid #d8d8d8!important;
    padding: 14px;
    border-radius: 5px;
    font-size: 12px;
}
.customFormControl::placeholder{
    color: #5E5F5E;
    font-weight: 400;
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

/*---- End Common Css ----*/
/* Start Choose Roles Css */
.detailsContainer {
    background: #FFFFFF;
    border-radius: 8px;
    padding: 4% 13%;
    /* margin: 40px 0; */
    display: block;
}
.rolesContainer {
    border: 1px solid #6E6E6E;
    border-radius: 5px;
    padding: 23px;
    cursor: pointer;
    transition: all 0.3s;
    height: 100%;
}
.rolesContainer .heading_ 
{
    letter-spacing: 2px;
}
.rolesContainer.active {
    background: #aec1fb;
    border: 1px solid #aec1fb;
}
.rolesContainer img
{
    width: 81px;
    height: 73px;
    object-fit: contain;
}
.accountRelate .innerTxt
{
    letter-spacing: 2px;
}
/* End Choose Roles Css */
/* Start Signup Css */
.continue {
    position: relative;
    margin: 40px 0;
    color: #636363;
    font-size: 16px;
}
.continue::before {
    position: absolute;
    content: '';
    width: 38%;
    height: 0.6px;
    background: #6e6e6e;
    left: -30px;
    top: 50%;
    transform: translate(0%, -50%);
}
.continue::after {
    position: absolute;
    right: -30px;
    width: 38%;
    background: #6e6e6e;
    content: '';
    height: 0.6px;
    top: 50%;
    transform: translate(0%, -50%);
}
/* End Signup Css */
/* Start MainHeader Css */
/* .nav-item {
    margin-right: 10px;
} */
.nav-item a{
    color: #fff!important;
    font-size: 15px !important;
    font-weight: 500;
    letter-spacing: 1px;
    padding: 8px 14px;
    transition: all .3s;
}
.nav-link {
    letter-spacing: 1px;
    color: #fff !important;
    font-size: 15px;
    font-weight: 500;
    transition: all 0.3s;
    padding: 8px 14px;
}
.navbar-nav {
    align-items: center !important;
}
.nav-link.active {
    font-weight: 500;
}
.navbar-brand img {
    width: 172px;
}
#basic-navbar-nav
{
    position: relative;
    height: 100% !important;
}
.loginDetails {
    display: flex;
    position: relative;
    align-items: center;
}
.loginDetails:before {
    /* position: absolute;
    content: '';
    background: #fff;
    height: 34px;
    width: 1px;
    left: -18px;
    top: 50%;
    transform: translate(-50%, -50%); */
}
.mainNavBar
{
    background: transparent;
    position: absolute;
    width: 100%;
    z-index: 9;
}
#basic-navbar-nav .primaryBtn {
    font-size: 14px;
}
/* End MainHeader Css */
/*============== Start Home Page ==============*/
/* Start HomeBannerPage Css */
.bannerHead {
    font-size: 42px;
    color: #fff;
    font-weight: 400;
}
.bannerInner{
    position: relative;
}
.bannerInner .goIcon{
    max-width: 100px;
}
.bannerInner .soundbiteImg{
    opacity: 0.8;
    border-radius: 20px;
}

.homeBanner {
    background: url('../images/bannerBg2.webp') no-repeat !important;
    background-size: cover;
    background-position: bottom;
    height: 100%;
    padding: 160px 0;
    overflow: hidden;
    width: 100%;
}
.homeBanner.resourceBanner{
    background: url('../images/bannerBg.webp') no-repeat !important;
    background-size: cover !important;
}
.bannerTxt {
    color: #fff;
    font-size: 23px;
    /* margin: 0; */
}
.leftSec
{
    /* z-index: 222; */
    position: relative;
    /* padding-top: 3rem; */
}
.videoBtn{
    position: absolute;
    top: 25%;
    width: 60px !important;
    height: 60px !important;
    left: 50%;
    transform: translate(-50%, 25%);
    cursor: pointer;
}
/* .rightImg {
    position: relative;
    top: auto;
    left: -56%;
    right: 0px;
    width: 59rem;
    transform: translate(0%, 0%);
    height: 100%;
    bottom: 4%;
} */
.rightImg {
    margin-top: 1.5rem;
    box-shadow: 1px 8px 16px hwb(0deg 0% 100% / 40%);
    border-radius: 16px;
}
/* End HomeBannerPage Css */
/* Start Buyer Service Css */
.buyerService {
    padding: 160px 0;
    background: #fff;
}
.buyerService .heading_Blue{
    font-size: 28px;
}
.secImg {
    width: 100%;
    /* max-width: 590px; */
    height: 451px;
    object-fit: cover;
    border-radius: 40px;
    display: block;
    /* margin: 0 auto; */
}
/* End Buyer Service Css */
/* Start Supplier Service Css */
.supplierService {
    padding: 160px 0;
}
.supplierService{
    background-color: #fff;
}
/* End Supplier Service Css */
/* Start Opportunites Css */
.opportunites {
    background: #f7f7f7;
    padding: 160px 0;
}
.opportunites .otherLink
{
    font-size: 18px;
    font-weight: bold;
}
.opptItems {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    background: #F1F1F1;
    margin: 0px 0px 10px 0;
    padding: 20px;
    transition: all 0.5s;
}
.opptItems.active {
    background: #1C3276;
}
.opptItems.active .innerSubtext
{
    color: #fff;
}
/* End Opportunites Css */
/* Start BussinessRelation Css */
.bussinessRelation {
    padding: 160px 0;
    background: #fff;
    color: #fff;
}
.bussinessRelation .heading_Blue
{
    color: #fff;
}
.bussinessRelation .innerSubtext
{
    /* color: #fff; */
}
.peopleImg {
    width: 100%;
    height: 100%;
}
.outerIcon {
    width: 40px;
    height: 40px;
    object-fit: contain;
}
.outerIcon1
{
    position: absolute;
    left: -10px;
    bottom: -10px;
    top: auto;
}
.outerIcon2
{
    position: absolute;
    right: -10px;
    bottom: -10px;
    top: auto;
}
.outerIcon3 {
    position: absolute;
    right: -10px;
    bottom: -10px;
}
.bussinessSector{
    font-size: 14px;
    font-weight: 600;
    color: #6E6E6E;
}
.commonResponse{
    position: absolute;
    background: #35be0e;
    padding: 10px;
    border-radius: 13px;
    bottom: -12px;
    left: -10px;
}
.bussinessRelLeftImg {
    position: relative;
}
.commonResponse img{
    width: 25px;
}
.commonResponse2{
    right: -10px !important;
    left: auto;
    z-index: 1;
}
.bussinessResource .homeBanner{
    background: url('../images/bannerBg.webp') no-repeat !important;
    background-size: cover !important;
}
/* End BussinessRelation Css */
/*----- Start WhatPeopleSay Css -----*/
.whatPeopleSay {
    padding: 90px 0;
    background: #fff;
}
.whatPeopleSay .owl-nav
{
    display: block !important;
}
.whatPeopleSay .heading_Blue{
    color:#111111 !important;
}
/* Start Slider Css */
.awssld__content
{
    background: transparent !important;
}
.awssld__bullets
{
    display: none !important;
}
.sliderContainer {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding: 0 10%;
}
.slideContent {
    margin-right: 36px;
}
/* .sliderItems {
    max-width: 50%;
    flex: 0 0 50%;
} */
.arrows {
    padding: 15px;
    border-Radius: 50%;
    background: #040404;
    opacity: 0.8;
    font-Size: 20px;
    height: 40px;
    width: 40px;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.arrows i
{
    display: flex;
    align-items: center;
}
/* End Slider Css */
/*----- End WhatPeopleSay Css -----*/
/*============== End Home Page ==============*/
/*============== Start AboutUs Page ==============*/
.aboutUs .homeBanner
{
    height: 100%;
    padding-top: 0%;
    padding-bottom: 0%;
    display: flex;
    flex-direction: column;
    background:url(../images/bannerBg.webp) no-repeat !important;
    background-size: cover !important;
}
.privacyCon{
    background:url(../images/bannerBg.webp) no-repeat !important;
    background-size: cover !important;
}
.outerPages{
    background:url(../images/bannerBg2.webp) no-repeat !important;
    background-size: cover !important;
    padding-top: 161px;
    padding-bottom: 44px;
}
.outerPages .heading_Blue {
    color: #405cb1;
    font-size: 45px;
    font-weight: 500;
}
.outerPages .innerSubtext {
    font-size: 24px !important;
    color: #232323 !important;
}
.aboutContainer {
    /* height: calc(100vh - 0px - 200px); */
    padding-top: 16rem;
    display: flex;
    align-items: end;
    padding-bottom: 1.5rem;
}
.aboutMenusContainer {
    display: flex;
    align-items: center;
    width: 100%;
    /* overflow: auto; */
}
.aboutMenus {
    padding: 10px 0 !important;
    background: #fff;
    border: 1px solid #e7e7e7;
    border-left: 0;
    border-right: 0;
}
.aboutItem {
    margin-right: 50px;
    white-space: pre;
}
.aboutLinks {
    font-size: 18px;
    color: #343434;
    font-weight: bold;
    text-transform: uppercase;
}
.aboutUs .outerPages{
    padding-top:220px !important
}
/* Start AboutFounder Css */
.aboutFounder {
    background-color: #ffffff;
    padding: 7em 0;
}
.aboutList{
    font-size: 24px;
    padding-left: 23px
}
.aboutList li{
    list-style: disc !important;
}
.founderSubHeading{
    color:#2f4ca6;
    font-weight: 500;
    font-size: 23px;
}
/* End AboutFounder Css */
/* Start Mission Css */
.mission {
    background: #f6f6f6;
    padding: 5rem 0;
}
.missionBoard {
    background: #ffffff;
    padding: 7rem 0;
}
/* End Mission Css */
/* Start LeaderShip Css */
.leadership {
    padding: 60px 0;
}
.leaderShipArea {
    display: flex;
    background: #fff;
    padding: 28px;
    box-shadow: 0px 20px 30px #00000005;
    border-radius: 15px;
    margin-bottom: 20px;
}
.leaderShipArea img {
    width: 100px;
    height: 100px;
    object-fit: contain;
}
/* End LeaderShip Css */
/* Start NewsPress Css */
.newsPress
{
    background: #f6f6f6;
    padding: 130px 0; 
}
.newsCard {
    background: #fff;
    border-radius: 15px;
    padding: 26px;
    height: 100%;
    position: relative;
    min-height: 386px;

}
.newsCard h5.innerSubtext
{
    line-height: 1.5;
}
.readMoreData
{
    /* margin-top: 63px; */
}
.newsCard .innerSubtext {
    font-size: 16px;
    font-weight: 400;
    text-align: left;
}
.newsCard .innerSubtextbold {
    font-size: 22px;
}
.readMore {
    font-size: 18px;
    color: #1C3276;
    font-weight: 600;
    position: absolute;
    bottom: 20px;
    text-decoration: underline;
    text-underline-position: from-font;
}
.missionBoard .innerSubtext {
    text-align: justify;
}
/* End NewsPress Css */
/*============== End AboutUs Page ==============*/
.authMenus :nth-child(2 of .authItems){
    margin-right: 4px;
}
/*============== Start AccountDashboard Page ==============*/
.opportunityViewImg{
    background-color: #3b3b3b;
    border-radius: 6px;
    padding:8px 12px;
    cursor: pointer;
}
.cardContainer, .CardContainer,.cardContainer_
{
    background-image: linear-gradient(to right, #3B55A7 ,#2B438E);
    border-radius: 8px;
}
.motionImg {
    border: 1px solid #c3c3c3;
    width: 150px;
    height: 150px;
    object-fit: cover;
    max-width: 150px;
    text-align: center;
    margin: 0 auto;
    border-radius: 8px;
    padding: 2px;
}
.motionSec {
    background: #fff;
    border-radius: 8px;
    padding: 20px 0;
    margin-bottom: 20px;
}
.companyProfile {
    background: #fff;
    border-radius: 8px;
    padding: 20px 0 0 0;
}
.newCompanyProfile .companyDetailsLinks {
    padding: 14px 24px;
    display: inline-block;
    color: #6e6e6e;
    font-size: 15px;
    font-weight: 400;
}
.newCompanyProfile .companyDetailsItems:last-child{
    background: #F6F6F6;
    border-top: 0;
    border-radius: 0 0 8px 8px;
}
.companyContainerArea .companyProfile {
    padding: 0;
}
.companyDetailsLinks {
    padding: 14px 24px;
    display: inline-block;
    color: #6E6E6E;
    font-size: 16px;
    font-weight: 500;
}
.companyDetailsLinks:hover {
    color: #0a58ca;
}
.motionSecData {
    margin: 10px 0;
    text-align: center;
}
.companyDetailsItems {
    border-top: 1px solid rgb(95 96 94 / 20%);
}
.companyDetailsItems:first-child{
    border: none;
}

.cardContainer {
    background-image: linear-gradient(to right, #3B55A7 ,#2B438E);
    border-radius: 8px;
    overflow: hidden;
    position: sticky;
    top: 90px;
}
.dashboardRightArea {
    height: 100%;
}
.CardContainer {
    background-image: linear-gradient(to right, #3B55A7 ,#2B438E);
    border-radius: 8px;
}
.cardImg img {
    height: 214px;
    width: 100%;
    object-fit: cover;
    object-position: 50% 40%;
}
.cardBodyArea {
    color: #fff;
    padding: 21px;
    word-break: break-word;
}
.cardBodyArea h5 {
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 2px;
}
.cardBodyArea p {
    color: #fff;
    font-size: 15px;
}
.cardBodyArea .primaryBtn
{
    font-size: 13px;
    padding: 10px 20px !important;
    font-weight: 600 !important;
}

/* .primaryBtn {
    font-size: 12px;
    font-weight: 500;
} */
/* Start PostSec Css */
.postSec {
    background: #fff;
    border-radius: 8px;
    padding: 20px 24px;
    /* height: 1006px;
    overflow: auto; */
}
.postSec::-webkit-scrollbar
{
    display: none;
}
.postSec .customFormControl
{
    width: 100%;
    border: 1px solid rgb(76 76 76 / 19%);
    padding: 12px;
    border-radius: 5px;
    font-size: 12px;
}
.posts
{
    display: flex;
    align-items: self-start;
    /* height: 100%;
    max-height: 200px;
    overflow: auto; */
}
.user_img {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    margin-right: 8px;
}
.downArrow_ i{
    vertical-align: top;
}
.postData .dropdown {
    min-width: auto;
    background: #ffffff;
    box-shadow: 0px 0px 10px rgb(0 0 0 / 5%);
    border-radius: 12px;
    width: 100%;
    display: flex;
    align-items: center;
}
.postData button {
    padding: 0;
    border: none;
    text-align: left;
    background-color: transparent;
    font-weight: 700 !important;
    color: #343434;
    font-size: 16px;
}
.postData button::after{
    display: none;
}
.postData small {
    display: flex;
    font-size: 14px;
    color: #91979d;
    font-weight: 400;
}
.postData .dropdown-menu {
    background: #ffffff;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
    border-radius: 15px;
    margin-top: 5px;
}
.postUser {
    width: 52px;
    height: 52px;
    object-fit: cover;
    border-radius: 8px;
    cursor: pointer;
    border: 1px solid #c3c3c3;
    padding: 2px;
}
.chatImg{
    width: 80px;
    height: 80px;
}
.postData .uploadImg{
    position: relative;
}
.uploadImg .crossIcon{
    position: absolute;
    right: 10px;
    top: 10px;
    background-color: #000000ad;
    color: #fff;
    width: 22px;
    height: 22px;
    border-radius: 30px;
    text-align: center;
    cursor: pointer;
}
.uploadImg img{
    border-radius: 10px;
}
.weeksTxt
{
    color: #2F4894;
    font-size: 12px;
    display: block;
}
.postContainerArea {
    border-top: 1px solid rgb(95 96 94 / 20%);
    /* margin-top: 20px; */
    padding: 20px 0 12px 0;
}
.postContainer .postContainerArea:first-child
{
    margin-top: 20px;
}
/* .companyContainerArea {
    height: 1006px;
    overflow: auto;
} */
.companyContainerArea {
    height: 100%;
}
.companyContainerArea::-webkit-scrollbar
{
    display: none;
}
.companyContainerArea::-moz-scrollbar
{
    display: none;
    scrollbar-width: none;
    -ms-overflow-style: none;
}
.companyContainerArea::-moz-scrollbar-track {
   display: none;
}
.postSec::-moz-scrollbar-thumb
{
    display: none;
}
.companyContainerArea::-webkit-scrollbar {
    display: none;
  }
/* End PostSec Css */
/* Start MessageInbox Css */
.messageContainer{
    background: #fff;
    border-radius: 8px;
    padding: 0px 24px;
    height: 308px;
    overflow: auto;
}
.messageContainer .postContainerArea:first-child
{
    border: 0;
    margin-top: 0 !important;
}
.messageContainer::-webkit-scrollbar
{
    display: none;
}
/* End MessageInbox Css */
/* Start Opportunites Css */
.opportunitiesContainer {
    background: #fff;
    padding: 0px 24px;
    border-radius: 8px;
    height: 340px;
    overflow: auto;
}
.opportunitiesContainer::-webkit-scrollbar
{
    display: none;
}
.opportunitiesSec .primaryBtnSmall
{
    padding: 6px 20px !important;
}
.opportunitiesContainer .postContainerArea:first-child
{
    border: 0;
}

/* End Opportunites Css */
.dashboardRightArea .cardImg img
{
    min-width: 150px;
    height: 100%;
    min-height: 226px;
}
.commonCartImg{
    display: flex;
}
/*============== End AccountDashboard Page ==============*/
/*============== Start Settings Page ==============*/
/* Start Setting Css */
.settings {
    margin: 40px 0;
}
.settingLinks {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #6e6e6e;
    font-weight: 500;
}
.settingLinks img {
    width: 15.9px;
    height: 15.9px;
    object-fit: contain;
    margin-right: 7px;
}
.settingItems {
    margin-bottom: 10px;
}
.topBorder::before {
    position: absolute;
    content: '';
    width: 100%;
    left: 0px;
    background: #6E6E6E;
    height: 1px;
    left: 0;
}
.advData .headingSmall_
{
    font-weight: 600;
}
.advData .headingTitleSmall_
{
    font-weight: 500;
}
.listingMenusArea {
    display: flex;
    /* border-bottom: 2px solid rgb(95 96 94 / 20%); */
    padding: 20px 0;
}
.listingMenus
{
    display: block;
    width: 100%;
}
.listingItems
{
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
}
.listingMenusArea .innerSubtext
{
    width: calc(100% - 70%);
}
.topLightboder
{
    height: 2px !important;
    background: rgb(95 96 94 / 20%);
    opacity: 1;
}
/* End Setting Css */
/* Start Subscription Css */
.colContainer
{
    display: flex;
    flex-direction: column;
    min-height: 100%;
}
.penCard {
    border-radius: 8px;
    border: 1px solid #D7D7D7;
    padding: 30px 24px;
    transition: all 0.3s;
    cursor: pointer;
    position: relative;
}
.penCard label
{
    display: block !important;
    padding-left: 0 !important;
}
.penCard .customRadio:checked + label:before, .penCard .customRadio:not(:checked) + label:before
{
    border: 2px solid #343434;
    right: 0 !important;
    top: -18px !important;
    left: auto;
}
.penCard.active .customRadio:checked + label:before, .penCard.active .customRadio:not(:checked) + label:before
{
    border: 2px solid #fff;
    right: 0 !important;
    top: -18px !important;
    left: auto;
}
.penCard.active .customRadio:checked + label:after, .penCard.active .customRadio:not(:checked) + label:after
{
    top: -13px !important;
    right: 5px !important;
    left: auto;
}
.penCard .customRadio:checked + label:after, .penCard .customRadio:not(:checked) + label:after
{
    top: -13px !important;
    right: 5px !important;
    left: auto;
}
.penCard.active {
    background: #3B57A9;
}
.penCard.active p, .penCard.active a {
    color: #fff;
}
.amountArea {
    margin-top: 30px;
}
.amountvalue {
    margin: 0;
    color: #343434;
    font-size: 20px;
    font-weight: bold;
}
.amountMsg {
    color: #A9A9A9;
    font-size: 12px;
}
.inputChecked {
    display: inline-block;
    position: absolute;
    top: 0px;
    right: 5px;
}
.pencardBottom .headTxtBlueSmall {
    font-size: 16px;
    font-weight: bold;
}
.pencardBottom .primaryBtn
{
    letter-spacing: 1.76px;
}
.pencardBottom, .promotionalCode
{
    margin-top: auto;
}
.bordeRight
{
    border-right: 1px solid #D7D7D7;
}
.search_group
{
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
    justify-content: space-between;
    border: 1px solid #6e6e6ec9;
    padding: 4px 4px 4px 15px;  
    border-radius: 5px;
}
.custoInput {
    display: block;
    width: 100%;
    border: 0;
    /* max-width: 200px; */
    font-size: 15px;
}
.inputSearchForm {
    width: 100%;
}
.custoInput::placeholder
{
    color: #5E5F5E;
    font-weight: 500;
}
/* End Subscrition Css */
/* Start Notification Css */
.notifications .postSec .postContainerArea:first-child
{
    margin-top: 0;
    border: 0;
    padding-top: 0;
}
/* End Notification Css */
/*============== End Settings Page ==============*/
/* Start Footer Css */
.footer {
    background: #fff;
    padding: 23px 0;
    margin-top: auto;
}
.footerMenus {
    margin-top: 10px;
}
.footerItems {
    margin-bottom: 8px;
}
.footerLinks {
    font-size: 16px;
    color: #262626!important;
    font-weight: 400;
    padding: 0;
}
.socialItems {
    display: inline-block;
    margin-right: 18px;
}
.socialLinks img {
    width: 30px;
    height: 30px;
    object-fit: contain;
}
.footerBottom {
    border-top: 1px solid hsl(0deg 0% 35% / 24%);
    margin-top: 30px;
    padding: 21px 0;
}
.twitter{
    /* filter: invert(63%) sepia(1%) saturate(2682%) hue-rotate(186deg) brightness(73%) contrast(89%); */
}
/* End Footer Css */
/* Start Business card */
.business_card {
    margin-bottom: 20px;
}
.imgproduct_ {
    width: 100%;
    height: 100%;
    max-width: 164px;
    max-height: 152px;
    border-radius: 8px;
    object-fit: cover;
    border: 1px solid #c3c3c3;
    padding: 1px;
}

/* custom pagination css */

.customPagination {
    display: flex;
    justify-content: end;
}
.paginationLeftArrow {
    position: absolute;
    top: 9px;
    left: 14px;
    transform: rotate(0deg);
    padding: 0 !important;
    color: #959393;
    cursor: pointer;
}
.paginationRightArrow {
    position: absolute;
    top: 8px;
    right: 12px;
    transform: rotate(-180deg);
    padding: 0 !important;
    color: #959393;
    cursor: pointer;
}

.customPagination .pagination {
    position: relative !important;
}
.customPagination .previous {
    background: #e2e2e2 !important;
    font-size: 17px;
    border-radius: 4px;
}
.customPagination .next {
    background: #e2e2e2 !important;
    font-size: 17px;
    border-radius: 4px;
}
.customPagination .previous i {
    color: #959393;
}
.customPagination .next i {
    color: #959393;
}
.customPagination .previous a {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    display: inline-flex;
    padding: 0 0;
}
.customPagination .next a {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    display: inline-flex;
    padding: 0 0;
}
.customPagination .selected {
    background: #4D6AC4;
    padding: 3px 11px;
    border-radius: 4px;
    color: #fff!important;
}
.customPagination li {
    background: #e2e2e2;
    padding: 3px 11px;
    border-radius: 4px;
    color: #767676;
    margin: 0 4px;
    font-weight: 600;
    font-size: 17px;
}
.customPagination li:last-child {
    margin-right: 0;
}
.customPagination ul {
    display: flex;
    align-items: center;
}



/* pagination */

/* .pagination-lg .page-link {
    padding: 0.75rem 1.5rem;
    font-size: 1.25rem;
    line-height: 1.5;
  }
  
  .pagination-lg .page-item:first-child .page-link {
    border-top-left-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem;
  }
  
  .pagination-lg .page-item:last-child .page-link {
    border-top-right-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
  }
  
  .pagination-sm .page-link {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
  }
  
  .pagination-sm .page-item:first-child .page-link {
    border-top-left-radius: 0.2rem;
    border-bottom-left-radius: 0.2rem;
  }
  
  .pagination-sm .page-item:last-child .page-link {
    border-top-right-radius: 0.2rem;
    border-bottom-right-radius: 0.2rem;
  }
   */
   .note_box{
    display:flex;
    align-items: center;
    justify-content: space-between;
   }
   .noteHeading {
    font-size: 12px;
    color: #ff1010;
    padding: 0 13px 10px;
}
.swal-button {
    background-color: #2F4894 !important;
}
.swal-button--cancel {
    color: #FFFFFF !important;
    background-color: #383838 !important;
}
.chip {
    background: #2d509a !important;
    white-space: pre-wrap !important;
    border-radius: 0 !important;
}

.highlightOption {
    background: transparent !important;
    color: #212529 !important;
}
.multiSelectContainer li:hover {
    background: #2d509a !important;
}
.addLabelList {
    height: 100%;
    max-height: 310px;
    overflow: auto;
    padding-right: 6px;
}
.addLabelList .labels_status {
    color: #8d8989;
    font-size: 15px;
    padding: 6px 0 0;
    font-weight: 500;
    width: 100%;
    word-wrap: break-word;
    background: #dddddd;
    margin-bottom: 9px;
    padding: 10px 11px;
    border-radius: 5px;
}
.reviewOption{
    padding-top: 20px;
    font-size: 18px;
    font-weight: 600;
    color: #000000;
}
/* css for auth nav company name */
.authItems .userName {
    color: #fff !important;
    font-weight: 400;
    white-space: pre;
}
.linkIndex_ {
    font-size: 13px;
    color: #3b56a7;
    margin: 0 0 7px;
    display: inline-block;
}
.reviewResponse .leftOpportunitycontent {
    margin-right: 40px;
    width: 100%;
}
.newApplication {
    display: flex;
    padding-right: 50px;
}
.reviewContent_ {
    display: flex;
    align-items: center;
    width: calc(100% - 185px);
}
.subheadingBlue_ {
    color: #435FB5;
    font-weight: bold;
    margin: 0;
    font-size: 36px;
}
.application_count .headingTitleLight_ {
    font-size: 14px;
    
}
.application_count {
    padding-right: 51px;
}
.newsubtext {
    color: #448d2f;
    font-weight: 500;
    font-size: 15px;
    bottom: 2px;
    left: 5px;
}
.responseBtn_ {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 185px;
}
.reviewResponse .subsmallBtnblack_ {
    font-weight: 600 !important;
    font-size: 13px;
    padding: 10px 20px;
    border-radius: 5px;
}
.responseBtn_ .dropdown-menu {
    background: #fff;
    border: none;
    box-shadow: 0 0 10px #eee;
    border-radius: 10px;
}
.viewRespondents .businessDesincontent_ {
    border: 1px solid #ebebeb;
    padding: 17px;
    border-radius: 10px;
}
.bluesubHeadd_ {
    color: #344D99;
    font-size: 16px;
    margin: 0;
}
.sgentbox_ {
    background-color: rgb(76,76,76,.11);
    border-radius: 5px;
    display: inline-block;
    padding: 8px 15px;
    font-size: 12px;
    margin-right: 10px;
    color: #979393;
}
.viewRespondents .imgproduct_ {
    max-width: 150px;
    max-height: 150px;
    height: 100%;
    min-height: auto;
}
.viewRespondents .weeksTxt {
    display: inline-block;
}
.viewRespondents .designContent_ .innerSubtextSmall {
    font-size: 14px;
    color: #686868;
    margin: 0;
}


.innerSubtextBold {
    color: #343434;
    font-size: 16px;
    margin: 0;
    font-weight: 600;
}

/***Priyanka**/

.aboutUs .otherLink {
    font-size: 17px;
    color: #5cc13e;
}
.aboutLady {
    width: 72%;
}
.aboutLadymain_ {
	display: flex;
	justify-content: right;
}
.mission .innerSubtext {
	line-height: 28px;
}
.containerBoard .innerSubtextBold {
    font-size: 22px;
    font-weight: 700;
}
.containerBoard .weeksTxt {
    font-size: 18px;
    font-weight: 500;
}
.containerBoard .innerSubtext {
    font-weight: 400;
    font-size: 16px;
}
.sucessCompny_ .anotherHeading {
    /* color: #5cc13e; */
    font-size: 30px;
}
.relevenceMain_ {
    background: #25439d;
}
.diversityList_ .bannerTxt {
    list-style: disc;
}
.diversityList_ {
    padding-left: 46px;
}
.diversityList_ .bannerTxt {
    list-style: disc;
    font-weight: 400;
    font-size: 17px;
    line-height: 30px;
}
.relevenceMain_ .streamImgchat {
    right: 13px!important;
    left: auto;
}
.headingBluemain_ {
    color: #1e3785;
    font-size: 25px;
    font-weight: 400;
}
/* .landingHead_ .bannerHead {
    line-height: 58px;
} */
.landingHead_ .bannerTxt {
    line-height: 35px;
}
.landingHead_ .boldhead_ {
    line-height: 28px;
}
.landingHead_ .boldhead_ b {
    /* line-height: 48px; */
}
.landingHead_ .heading_Blue {
    font-size: 45px !important;
}
.landingHead_ .innerSubtext{
    font-size: 23px;
}
.landingHead_ .btn-group {
    margin-top:6rem;
}
.homePage .heading_Blue {
    color: #405cb1;
    font-size: 30px;
    font-weight: 500;
}
.homeRelevance .innerSubtext {
    /* color: #405cb1; */
    /* line-height: 31px; */
    font-size: 16px;
}
#community .heading_Blue {
    /* color: #fff; */
}
/* .allConnection_ .motionImg {
    width: 100%;
    height: 198px;
    object-fit: initial;
} */
.connectionbtn_ {
    display: flex;
    justify-content: center;
}
.postContainerArea video {
    margin-top: 15px;
    width: 100%;
    object-fit: contain;
}
.swal-footer {
    text-align: center !important;
}





/* Tags Css start */

.postTags {
    padding: 0px 10px 0px 10px;
}
.postTags .tag,.postTags .add-tag{
    /* background-color: #3e5ab1;
    color: white;
    font-size: 15px;
    border-radius: 11px;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    height: 30px;
    font-weight: 500;
    padding: 4px 10px;
    width: auto;
    margin: 4px 4px; */
    background-color: #3e5ab121;
    color: #3b57ab;
    font-size: 14px;
    border-radius: 16px;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    height: 27px;
    font-weight: 500;
    padding: 4px 10px;
    width: auto;
    margin: 4px 4px;
}

.postTagsList {
    padding: 5px 10px 0px 10px;
    width: 100%;
}

.postTagsList .tag {
    background-color: #3e5ab121;
    color: #3b57ab;
    font-size: 12px;
    border-radius: 16px;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    height: 25px;
    font-weight: 500;
    padding: 4px 10px;
    width: auto;
    margin: 4px 4px;
}

.postTags .tag .close {
    cursor: pointer;
    margin-left: 4px;
}
.postTags .add-tag{
    cursor: pointer;
}
.tagBox.active {
    border-bottom: 2px solid #3B56A7;
    width: 50%;
}
.add-tag-to-post .tagBox{
    width: 50%;
    background: none;
    color: #000 !important;
}
.tagList{
    height: 100%;
    max-height: 300px;
    overflow: auto;
    margin-top: 20px;
}
#addTagToPost .primaryBtn {
    font-size: 12px;
    padding: 10px 27px !important;
    border-radius: 5px;
}
/* Tags Css end */
/* have question */
.questionBox {
    padding: 90px 0;
    background: #fff;
}
.queryBox {
    background: #cdcdcd;
    border-radius: 20px;
    padding: 7rem 40px;
    position: relative;
}
.haveQuestion {
    position: absolute;
    right: 0;
    bottom: 0;
}
/* home page start */
.ColoredText{
   color: #5cc13e;
}
#Endorsements .modal-header {
    border-bottom: none !important;
}
#Endorsements .modal-header .btn-close {
    border: 1px solid #cdcdcd;
    border-radius: 50%;
}
.deviserCompany_ p {
    text-align: justify;
}
.composeProfileBox{
    display: flex;
    align-items: center;
    margin: 10px;
    padding: 5px;
}
#ComposeMessage .composeProfile {
    width: 50px;
    height: 50px;
    border-radius: 8px;
    object-fit: cover;
    border: 1px solid #c3c3c3;
    padding: 1px;
}
.composeProfileBox:hover{
    color: #FFFFFF;
    background-color: #efeff1;;
    width: 100%;
}
#HomeThumbnail .modal-dialog {
    max-width: 800px !important;
    /* margin: 1.75rem auto; */
}
.composeProfileBox a.dropdown-item{
    background-color: transparent !important;
    color:#000;
}
.opportunityComtent_ .headingTitleSmall_ {
    display: inline-block;
}
.reSharePostOuter {
    border: 1px solid #bfbfbf;
    padding: 15px 16px;
    border-radius: 5px;
}

.fadeImage{
	opacity:'1';
	transition: 0.3s
}
.notFadeImage{
	opacity:'0';
	transition: 0.3s
}

.response_toggles {
    padding: 5px 8px;
    color: black;
    font-size: 16px;
    font-weight: 500;
}

.active_btn {
    color : white;
    background-color: gray;
    border-radius: 5px;
}

.dashboard_response_container .spin_loader { 
    padding: 0 !important;
}